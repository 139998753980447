<template>
  <input
    id="DTPO"
    v-model="displayTypeIsCard"
    type="checkbox"
    class="checkbox grid"
  />
  <label for="DTPO">
    <transition name="modal" mode="out-in">
      <span v-if="displayTypeIsCard" class="material-icons-round icon">
        format_list_bulleted
      </span>
      <span v-else class="material-icons-round icon"> grid_view </span>
    </transition>
  </label>
</template>

<script>
import { useDisplayType } from '../../model';
export default {
  setup() {
    return { ...useDisplayType() };
  },
};
</script>

<style lang="scss" scoped>
.icon {
  color: #757575;
  font-size: 28px;
  font-weight: 600;
}
.grid {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.grid + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  position: relative;
}
.grid + label::before {
  content: "";
  display: inline-block;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 0.25em;
  position: absolute;
  border: none;

  // @include bg_image("@/assets/grid.svg", 100%);
  cursor: pointer;
  background: transparent !important;
}
</style>
