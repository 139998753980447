import store from '@/store';
import { useOrdersPipelinesSelect } from './ordersPipelinesSelect';
import { nextTick, onMounted } from 'vue';
import { computed } from 'vue';
import { useValidate } from './validate';
import { ref } from 'vue';
import { useLangConfiguration } from './langConfiguration';

const isKommo = computed(() => store.state.account.account.is_kommo);

const userList = ref([]);

export function usePreparationOrders() {
  const { t } = useLangConfiguration();
  const { formatNumber, dateFormater } = useValidate();
  onMounted(async () => {
    if (!userList.value.length) {
      const users = await store.dispatch('getUsersList');
      userList.value = users;
    }
  });

  let pipelines = null;
  nextTick(() => {
    const { pipelines: pip } = useOrdersPipelinesSelect();
    pipelines = pip;
  });

  const statList = [
    {
      name: t('statusesForAmo.opened'),
      value: 0,
    },
    {
      name: t('statusesForAmo.reserved'),
      value: 1,
    },
    {
      name: t('statusesForAmo.success'),
      value: 2,
    },
    {
      name: t('statusesForAmo.unsuccessful'),
      value: 3,
    },
    {
      name: t('statusesForAmo.deleted'),
      value: 4,
    },
  ];

  const getStatusType = (status, status_id, isAmo) => {
    if (isAmo) {
      return status;
    } else {
      const status = pipelines.allStatuses?.find((el) => el.id == status_id);
      return status?.type && status?.type + 1;
    }
  };

  const getValue = (value, code) =>
    value?.[code] || value?.fields?.[code] || '';

  const preparationValueByType = (value, field) => {
    if (field.type === 8) return value && dateFormater(value);
    return value;
  };

  const preparationOrder = (value) => {
    const res = {};
    res.img = !value.lead_id
      ? require('@/assets/favicon2.png')
      : isKommo.value
      ? require('@/assets/kommo.png')
      : require('@/assets/amocrm.png');
    store.getters.preporatedTableConfig.forEach((field) => {
      const [source, code] = field.value.split(/_(.*)/s);
      let stepRes = null;

      if (source === 'order') {
        stepRes = getValue(value, code);
        if (code === 'sum')
          // stepRes = String(formatNumber(stepRes)) + ' ' + value.currency;
          stepRes = String(formatNumber(stepRes))
        if (code === 'budget')
          // stepRes = String(formatNumber(stepRes)) + ' ' + value.currency;
          stepRes = String(formatNumber(stepRes))
        if (code === 'profit')
          // stepRes = String(formatNumber(stepRes)) + ' ' + value.currency;
          stepRes = String(formatNumber(stepRes))
        if (code === 'cost_price')
          // stepRes = String(formatNumber(stepRes)) + ' ' + value.currency;
          stepRes = String(formatNumber(stepRes));
        if (code === 'user') {
          stepRes = userList.value.find(
            (user) => user.id == getValue(value, 'user_id')
          )?.name;
        }
        if (code === 'status') {
          if (value.lead_id) {
            stepRes = statList.find(
              (el) => el.value == getValue(value, 'status')
            )?.name;
          } else {
            stepRes = pipelines.allStatuses.find(
              (el) => el.id == getValue(value, 'status_id')
            )?.name;
          }
        }
      } else if (source === 'company') {
        stepRes = getValue(value.company, code);
      } else if (source === 'contact') {
        const contacts = [];
        value.contacts?.forEach((contact) =>
          contacts.push(getValue(contact, code))
        );
        stepRes = contacts.join(', ');
      } else if (source === 'product') {
        const contacts = [];
        value.positions?.forEach((position) => {
          position.title = `${position?.price}, ${position?.lead?.name} | ${position?.count} ${position?.fields?.units} |`

          const val1 = getValue(position, code);
          const val2 = getValue(position.product, code);
          contacts.push(prePreparationValueByType(val1 || val2, field));
        });
        stepRes = contacts.join(', ');
      }

      stepRes = preparationValueByType(stepRes, field);

      if (stepRes !== null && stepRes !== undefined) res[field.value] = stepRes;
    });

    res.statusType = getStatusType(
      value.status,
      value.status_id,
      Boolean(value.lead_id)
    );

    return res;
  };
  const categories = computed(() => store.state.categories.fields_properties);
  nextTick(() => {
    if (!categories.value.length && !store.state.categories.isLoading)
      store.dispatch('get_fields_properties');
  });

  const prePreparationValueByType = (value, field) => {
    let intermediate = value;
    if (field.type === 1 || field.type === 2) {
      if (!value) intermediate = 0;
      intermediate = Number(value);
      if (field.value === 'product_free_4_reserve' ?? value == -1)
        intermediate = '∞';
    } else if (field.type === 3 || field.type === 4) {
      if (!value) intermediate = '';
      intermediate = String(value);
    } else if (field.type === 5 || field.type === 6) {
      if (!value) intermediate = '';
    } else if (field.type === 7 || field.type === 8) {
      if (!value) intermediate = '';
    } else if (field.type === 9) {
      if (!value) intermediate = false;

      if (value == '0') intermediate = false;
      if (value == '1') {
        intermediate = true;
      } else {
        intermediate = Boolean(value);
      }
    } else if (field.type === 12) {
      const cat = categories.value.find((el) => el.id === value);
      if (cat) intermediate = cat.name;
    } else if (field.type === 11) {
      intermediate = formatNumber(
        Number(typeof value === 'object' ? value.cost : value)
      );
    }

    return intermediate;
  };

  return { preparationOrder };
}
