import { createRouter, createWebHashHistory } from 'vue-router';
import {
  POToken,
  POTokenName,
  haveAnyTOKEN,
  isPOTokenGetted,
  useRedirectToAuth,
} from '@/composables/BaseURL';
import store from '../store';
import { useCheckDevMode } from '@/composables/checkDevMode';
import { waitForNonAsyncFunction } from '@/composables/waitForNonAsyncFunction';
import { computed } from 'vue';
import { useSaveLS } from '@/composables/saveLS';
import { POLIST } from '@/components/PublicOrder';

const { getSavedLSParam } = useSaveLS();
const { isDev } = useCheckDevMode();
const { isTokenFail } = useRedirectToAuth();

const routes = [
  {
    path: '/remnants',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/qwe',
    name: 'qwe',
    component: () => import('../views/QweView.vue'),
  },
  {
    path: '/archive',
    name: 'archive',
    component: () => import('../views/ArchiveView.vue'),
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('../views/AnalyticsView.vue'),
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('../views/DocumentsView.vue'),
  },
  {
    path: '/genezis',
    name: 'genezis',
    component: () => import('../views/GenezisView.vue'),
  },
  {
    path: '/amoCRM',
    name: 'amoCRM',
    component: () => import('../views/AmoCrmView.vue'),
  },
  {
    path: '/kommo',
    name: 'kommo',
    component: () => import('../views/KommoView.vue'),
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/OrdersView.vue'),
  },
  {
    path: '/Error_is_not_installed',
    name: 'Error_is_not_installed',
    component: () => import('../views/ErrorIsNotInstalledView.vue'),
  },
  {
    path: '/Error_is_expired',
    name: 'Error_is_expired',
    component: () => import('../views/ErrorIsExpiredView.vue'),
  },
  {
    path: '/Error_token_not_valid',
    name: 'NotValidToken',
    component: () => import('../views/NotValidToken.vue'),
  },
  {
    path: '/authorization',
    name: 'authorization',
    component: () => import('../views/AuthorizationView.vue'),
  },
  {
    path: '/accountSettings',
    name: 'accountSettings',
    component: () => import('../views/AccountSettings.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfileView.vue'),
  },
  {
    path: '/addToDeal',
    name: 'addToDeal',
    component: () => import('../views/AddToDealView.vue'),
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('../views/ClientsView.vue'),
  },
  {
    path: '/usersConfiguration',
    name: 'usersConfiguration',
    component: () => import('../views/UsersConfigurationView.vue'),
  },
  {
    path: '/publicOrder',
    name: 'publicOrder',
    component: () => import('../views/AddToDealView.vue'),
  },
  {
    path: '/availablePublicOrderList',
    name: 'AvailablePublicOrderList',
    component: () => import('../views/AvailablePublicOrderList.vue'),
  },
  {
    path: '/publicOrderAuth',
    name: 'CheckPublicOrderAuth',
    component: () => import('../views/CheckPublicOrderAuth.vue'),
  },
  {
    path: '/publicOrderProfile',
    name: 'PublicOrderProfile',
    component: () => import('../views/PublicOrderProfile.vue'),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (POToken && !isPOTokenGetted.value && to.path === '/publicOrder')
    await waitForNonAsyncFunction(computed(() => isPOTokenGetted.value));
  if (
    (!POToken || !getSavedLSParam(POTokenName)) &&
    isPOTokenGetted.value &&
    POLIST.includes(to.path) &&
    (to.path === '/publicOrder' || to.path === '/publicOrderAuth')
  ) {
    if (to.path === '/publicOrderAuth') {
      next();
      return;
    }
    next('/publicOrderAuth');
    return;
  }
  if (
    !POToken &&
    !POLIST.includes(to.path) &&
    !haveAnyTOKEN() &&
    to.path !== '/authorization'
  ) {
    next('/authorization');
    return;
  }
  if (to.path == '/authorization') {
    if (haveAnyTOKEN() && !isTokenFail.value && !isDev.value) {
      next(from.path === '/authorization' ? '/' : from.path);
      return;
    }
    next();
    return;
  }

  if (
    from.path === '/Error_token_not_valid' &&
    to.path !== '/Error_token_not_valid' &&
    isTokenFail.value
  ) {
    next('/Error_token_not_valid');
    return;
  }
  if (to.path === '/Error_token_not_valid' && !isTokenFail.value) {
    next('/');
    return;
  }
  if (POToken && POLIST.includes(to.path) && to.path === '/publicOrder') {
    next();
    return;
  }
  if (POToken && POLIST.includes(to.path)) {
    next();
    return;
  }

  const account = store.state.account?.account;
  if (!account?.id) await store.dispatch('get_account');
  // if (!account?.install && to.path != "/Error_is_not_installed") {
  //   next("/Error_is_not_installed");
  //   return;
  // }

  if (
    // (account?.install && to.path == "/Error_is_not_installed") ||
    !account?.expired &&
    to.path == '/Error_is_expired'
  ) {
    next('/');
    return;
  }

  if (account?.expired && to.path != '/Error_is_expired') {
    next('/Error_is_expired'); // не забудь переключить !expired => expired
    return;
  }

  if (to.path == '/') {
    if (account?.id == 148) {
      next('/documents');
      return;
    } else {
      next('/orders');
      return;
    }
  } else next();
});

export default router;
